import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets')) {
      return next.handle(req);
    }

    return req.headers.has('BypassTokenCheck')
      ? next.handle(
          req.clone({
            url: environment.baseURL + req.url,
            setHeaders: {
              'Content-Type': 'application/json'
            }
          })
        )
      : this.authService.getTokenSilently$().pipe(
          mergeMap(token => {
            let tokenReq = req.clone({
              url: environment.baseURL + req.url,
              setHeaders: {
                Authorization: token.access_token,
                'Content-Type': 'application/json'
              }
            });
            return next.handle(tokenReq);
          })
        );
  }
}
