import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LogInterceptor implements HttpInterceptor {
  constructor(private router: Router, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 401) {
          this.snackBar.open('Unauthorized. Please Login.', '', {
            duration: 5000
          });
          this.router.navigateByUrl('/auth/login');
        } else if (event instanceof HttpErrorResponse && event.status === 400) {
          this.snackBar.open(`${event.error.errors}`, '', {
            duration: 5000
          });
        } else if (event instanceof HttpErrorResponse && event.status === 500) {
          this.snackBar.open('Server Error Occurred.', '', {
            duration: 5000
          });
        }
        return throwError(() => event);
      })
    );
  }
}
