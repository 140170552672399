import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './shared/components/loading/loading-spinner.component';
import { CallbackResolver } from './shared/services/callback.resolver';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      {
        path: 'callback',
        resolve: {
          callback: CallbackResolver
        },
        component: LoadingSpinnerComponent
      },
      { path: '**', redirectTo: '/auth/login' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
