import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (window?.newrelic?.noticeError) {
      window.newrelic.noticeError(error);
    }
    console.error(error);
  }
}
