import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY, concatMap, map, take } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CallbackResolver  {
  constructor(private authService: AuthService, private router: Router) {}

  resolve(): Observable<void> {
    return this.authService.handleAuthCallback().pipe(
      concatMap(result => {
        if (result.loggedIn) {
          this.router.navigate([result.targetUrl]);
          return EMPTY;
        } else if (!result.loggedIn) {
          this.authService.login();
          return EMPTY;
        } else {
          this.authService.auth0Client$.pipe(map(client => client.logout()));
          return EMPTY;
        }
      }),
      take(1)
    );
  }
}
