import { Component } from '@angular/core';
import { PwaService } from './shared/services/pwa.service';

@Component({
  selector: 'root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor(private pwaService: PwaService) {}
}
